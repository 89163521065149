import galleryData from "./galleryData"

const serviceData = [
    {
        id:1,
        slugs:"/golden-knee-replacement",
        ServiceName: "Golden Knee Replacement",
        serviceImg:galleryData.knee,
        img1: galleryData.knee1,
        img2:galleryData.PartialKnee,
        img3: galleryData.steelKnee,
        ServiceDescription:"The constant use of the lower extremities makes them an easy target for injury and overuse, specifically in the knees. Walking, sitting, and standing all put pressure on our knees and most athletic activities rely on a healthy knees. Surgery may be required when non-surgical treatment programs have failed, when there is an associated fracture, or when there is a complete tear.",
        descp2:`Golden Knee Replacement in Kandivali is performed to relieve severe knee pain and improve mobility for individuals suffering from advanced arthritis or significant joint damage. This procedure uses advanced materials to provide better durability and longevity compared to traditional knee replacements.`,
        descp3:`Golden knee replacement is recommended for individuals with severe knee arthritis who have not found relief from conservative treatments such as medication, physical therapy, or injections. It is particularly suitable for patients who are allergic to standard implant materials or who have a higher risk of implant wear and tear.`,
        descp4:`Images of golden knee replacement surgery showcase the use of advanced prosthetic implants made from durable, biocompatible materials with a golden hue. These visuals highlight the precision and expertise required for successful joint replacement procedures.`,
        ben1:`Gold implants offer increased strength and a longer lifespan compared to traditional materials.`,
        ben2:`The gold alloy minimizes friction, reducing wear and tear over time.`,
        ben3:`Gold is highly compatible with the human body, reducing the risk of allergic reactions.`,
        ben4:`Gold knee replacements provide smoother joint motion, improving mobility and comfort.`,
        ben5:`The gold finish offers a unique and visually appealing look for the knee implant.`,
        title:`Golden Knee Replacement in Kandivali | Sonik Shah`,
        slug_descp:`Explore Golden Knee Replacement options in Kandivali for effective surgical solutions. Our expert surgeons provide personalized care.`,
        keyword:`Golden Knee Replacement in Kandivali`,
    },
    {
        id:1,
        slugs:"/knee-surgeon-kandivali",
        ServiceName: "Knee Replacement Surgery",
        serviceImg:galleryData.knee1,
        img1: galleryData.kneesurger1,
        img2:galleryData.kneesurger2,
        img3: galleryData.kneesurger3,
        ServiceDescription:"If you're looking for a knee surgeon in Kandivali, consider checking local hospitals or clinics for specialists who can provide expert care tailored to your needs. Researching online reviews and patient testimonials can also help you make an informed decision.",
        descp2:`Knee replacement surgery is performed to relieve pain and restore function in severely diseased knee joints, typically due to arthritis or injury. Consulting a skilled knee surgeon in Kandivali can help determine if this procedure is the best solution for your knee pain and mobility issues.`,
        descp3:`Individuals experiencing severe knee pain, limited mobility, and diminished quality of life due to advanced arthritis or knee joint damage should consider consulting a knee surgeon in Kandivali for a potential knee replacement surgery. This procedure is often recommended when conservative treatments have failed to provide adequate relief and improve daily function.`,
        descp4:`If you're seeking a knee surgeon in Kandivali, consider reviewing before-and-after surgery images to assess the quality of the surgeon's work. This visual evidence can provide valuable insight into the expertise and outcomes you can expect from a knee surgeon in Kandivali.`,
        ben1:`Knee replacement surgery can significantly reduce or eliminate chronic pain caused by arthritis or injury.`,
        ben2:`The surgery restores function to the knee, making it easier to walk, climb stairs, and engage in other physical activities.`,
        ben3:`With pain reduced and movement improved, patients experience a better overall quality of life, including better participation in social and recreational activities.`,
        ben4:`Modern knee implants are designed to last for many years, providing long-term relief from knee issues.`,
        ben5:`Patients regain the ability to perform daily tasks without assistance, improving self-reliance and reducing the need for assistive devices.`,
        title:`  Knee Surgeon in Kandivali | Sonik Shah`,
        slug_descp:`Find an expert Knee Surgeon in Kandivali for specialized knee care. Our surgeon offers advanced treatment options tailored to your needs.`,
        keyword:`Best Trauma Surgeons in Kandivali`,
    },
    {
        id:1,
        slugs:"/partial-knee-replacement",
        ServiceName: "Partial Knee Replacement",
        serviceImg:galleryData.partial1,
        img1: galleryData.partial2,
        img2:galleryData.partial3,
        img3: galleryData.PartialKnee,
        ServiceDescription:"Partial Knee Replacement in Kandivali is a surgical procedure where only the damaged compartment of the knee is replaced, preserving the healthy bone and tissue. This minimally invasive option offers quicker recovery and less postoperative pain compared to a total knee replacement.",
        descp2:`Partial knee replacement in Kandivali is done to relieve pain and restore function in patients with arthritis that affects only a portion of the knee. This procedure offers a faster recovery and less postoperative pain compared to total knee replacement.`,
        descp3:`Partial knee replacement is typically recommended for patients with osteoarthritis confined to a single compartment of the knee who have not responded to non-surgical treatments. Ideal candidates are usually older adults with a stable knee, good range of motion, and a healthy body weight.`,
        descp4:`If you're considering a partial knee replacement in Kandivali, examining before-and-after surgery images can offer valuable insights into the quality of the surgeon's work. These visual records provide a clear indication of the expertise and expected outcomes from a partial knee replacement in Kandivali.`,
        ben1:` Partial knee replacement involves less invasive surgery, leading to a faster recovery time compared to total knee replacement.`,
        ben2:`The surgery only replaces the damaged part of the knee, preserving more of the healthy joint tissue and function.`,
        ben3:`Partial knee replacement can provide significant relief from pain while maintaining a natural feel and movement in the knee.`,
        ben4:`With less extensive surgery, the risk of complications such as infection or blood clots is reduced.`,
        ben5:`Since the procedure is less invasive, patients can usually return to their normal activities, including sports, sooner than with total knee replacement.`,
        title:`Partial Knee Replacement in Kandivali | Sonik Shah`,
        slug_descp:` Discover Partial Knee Replacement options in Kandivali for targeted joint care. Our expert surgeons specialize in advanced techniques.`,
        keyword:`Partial Knee Replacement in Kandivali`,
    },
    {
        id:1,
        slugs:"/best-trauma-surgeons-kandivali",
        ServiceName: "Trauma Surgery",
        serviceImg:galleryData.trauma1,
        img1:galleryData.trauma2,
        img2:galleryData.trauma3,
        img3: galleryData.trauma4,
        ServiceDescription:`Trauma surgery involves the surgical management of injuries caused by accidents or emergencies, aiming to stabilize and treat patients suffering from severe physical trauma. The best trauma surgeons in Kandivali specialise in rapid assessment, surgical intervention, and critical care to improve outcomes for trauma patients.`,
        descp2:`Trauma surgery, performed by the best trauma surgeons in Kandivali, is necessary to promptly stabilise and treat severe injuries such as gunshot wounds or car accidents, aiming to prevent further complications and ensure the patient's survival and recovery.`,
        descp3:`
Trauma surgery is typically required for patients who have sustained severe injuries, such as those from car accidents, falls, or penetrating wounds, where immediate surgical intervention is necessary to stabilize and treat life-threatening conditions. These surgeries are often performed in trauma centres equipped with specialised resources and teams trained to manage critical injuries.
`,
        descp4:`When seeking the best trauma surgeons in Kandivali for a partial knee replacement, reviewing before-and-after surgery images can provide crucial insights into the quality of their work. These visual records offer a clear indication of the surgeon's expertise and the expected outcomes from the procedure.`,
        ben1:` Trauma surgery helps alleviate severe pain caused by fractures or injuries, providing fast relief.`,
        ben2:`It aims to restore the normal function of the affected bones, joints, and soft tissues, improving mobility.`,
        ben3:` Surgery can prevent additional injury or complications, ensuring the best possible healing outcome.`,
        ben4:`Proper surgical intervention can help speed up the healing process, allowing patients to recover faster and regain strength.`,
        ben5:` Trauma surgery provides a foundation for long-term recovery, reducing the risk of permanent disability or loss of function.`,
        title:`Best Trauma Surgeons in Kandivali | Sonik Shah`,
        slug_descp:`Find the best trauma surgeons in Kandivali for expert emergency care. Our skilled team specializes in treating traumatic injuries with precision and compassion.`,
        keyword:`Best Trauma Surgeons in Kandivali`,
    },
    {
        id:1,
        slugs:"/best-shoulder-specialist-kandivali",
        ServiceName: "Shoulder Replacement Surgery",
        img1: galleryData.sholder1,
        img2:galleryData.sholder2,
        img3: galleryData.sholder3,
        serviceImg:galleryData.sholder4,
        ServiceDescription:"This joint is the most flexible one in the body and allows for a full range of motion, but also makes the shoulder a common source of injury and instability. Patients can benefit from less tissue damage, shorter recovery times and less scarring with arthroscopic techniques. This procedure can also be used for diagnostic purposes after a physical examination and other imaging procedures have been performed.",
        descp2:`Shoulder replacement surgery is a procedure where damaged parts of the shoulder are replaced with artificial components to relieve pain and restore function. Consulting the best shoulder specialist in Kandivali ensures expert care and optimal outcomes for this complex surgery.`,
        descp3:`Shoulder replacement surgery is typically recommended for individuals with severe shoulder joint damage or arthritis that significantly limits their range of motion and causes chronic pain unrelieved by conservative treatments. Candidates often include those with osteoarthritis, rheumatoid arthritis, post-traumatic arthritis, rotator cuff tear arthritis, or avascular necrosis.`,
        descp4:`When seeking the best shoulder specialist in Kandivali, reviewing before-and-after surgery images can provide crucial insights into the quality of their work. These visual records offer a clear indication of the specialist's expertise and the expected outcomes from the procedure.`,
        ben1:`Shoulder replacement surgery effectively reduces or eliminates chronic pain caused by arthritis or injury.`,
        ben2:`The surgery restores movement and flexibility to the shoulder, making daily activities easier.`,
        ben3:`Patients often experience a significant improvement in overall quality of life, with reduced pain and better mobility.`,
        ben4:`Modern shoulder replacements are designed for durability, providing long-term relief from shoulder joint problems.`,
        ben5:` With advances in surgical techniques, many patients experience a quicker recovery and can resume normal activities sooner.`,
        title:`Best Shoulder Specialist in Kandivali | Sonik Shah`,
        slug_descp:`Discover the Best Shoulder Specialist in Kandivali for expert shoulder care. Our specialist offers advanced treatment options tailored to your needs.`,
        keyword:`best shoulder specialist in Kandivali`,
        
    },
    {
        id:1,
        slugs:"/robotic-knee-replacement-surgeon-kandivali",
        ServiceName: "Robotic Knee Replacement Surgery",
        serviceImg:galleryData.robot1,
        img1: galleryData.robot2,
        img2:galleryData.robot3,
        img3: galleryData.robot4,
        ServiceDescription:"TRobotic knee replacement surgery is an advanced procedure where a robotic arm assists the surgeon in accurately aligning and placing the knee implant, enhancing precision and outcomes. If you're looking for this advanced surgical option, consult a robotic knee replacement surgeon in Kandivali to ensure you receive expert care and the latest in medical technology.",
        descp2:`Robotic knee replacement surgery is performed to provide patients with a more precise and minimally invasive option for knee joint replacement, resulting in quicker recovery times and improved outcomes. A robotic knee replacement surgeon in Kandivali can offer this advanced procedure, ensuring patients receive the benefits of cutting-edge technology and expert care.`,
        descp3:`Robotic knee replacement surgery is typically recommended for patients with severe knee arthritis or injury who have not responded adequately to conservative treatments like medication or physical therapy. It may be particularly beneficial for those needing precise implant placement and alignment, potentially offering faster recovery and improved long-term outcomes. Surgery images `,
        descp4:`When searching for the top robotic knee replacement surgeon in Kandivali, examining before-and-after surgery images can offer valuable insights into the surgeon's proficiency. These visual records provide a clear demonstration of the specialist's expertise and the anticipated results of the procedure.`,
        ben1:` Robotic assistance allows for highly accurate alignment and positioning of the knee implant, improving surgical outcomes.`,
        ben2:`The surgery is tailored to each patient's specific knee anatomy, ensuring a more customized fit for the implant.`,
        ben3:`Robotic techniques enable smaller incisions and less disruption of surrounding tissues, leading to reduced pain and faster recovery.`,
        ben4:`With less invasive procedures and improved precision, recovery time is often shorter compared to traditional knee replacement surgery.`,
        ben5:`The enhanced accuracy of robotic surgery helps achieve better implant placement, leading to improved knee function and longer-lasting results.`,
        title:`Robotic Knee Replacement Surgeon in Kandivali | Sonik Shah`,
        slug_descp:'Meet the leading Robotic Knee Replacement Surgeon in Kandivali specializing in advanced joint surgery. ',
        keyword:`Robotic Knee Replacement Surgeon in Kandivali`

    },
    {
        id:1,
        slugs:"/best-orthopedic-kandivali",
        ServiceName: "Orthopedic Services",
        serviceImg:galleryData.orth1,
        img1: galleryData.orth2,
        img2:galleryData.orth3,
        img3: galleryData.orth4,
        ServiceDescription:"Orthopedic services in Kandivali focus on diagnosing and treating musculoskeletal conditions, ensuring optimal bone and joint health. Seeking the best orthopedic care in Kandivali involves finding skilled specialists who provide comprehensive treatment for injuries, arthritis, and other orthopedic disorders.",
        descp2:`Orthopedic surgery is performed by the best orthopedic surgeons in Kandivali to treat various conditions such as fractures, joint injuries, and musculoskeletal deformities, aiming to alleviate pain, improve mobility, and restore function to affected areas. These specialists utilize advanced techniques to ensure optimal recovery and long-term health outcomes for their patients.`,
        descp3:`Orthopedic services are typically recommended for individuals experiencing musculoskeletal issues such as joint pain, fractures, or deformities that affect daily functioning or quality of life, requiring specialized medical intervention and treatment. Patients may include those with sports injuries, arthritis, spinal conditions, or those needing joint replacement surgeries.`,
        descp4:`When looking for the best orthopedic surgeon in Kandivali specializing in robotic knee replacement, reviewing before-and-after surgery images can provide valuable insights. These visual records effectively showcase the specialist's skill and the expected outcomes of the procedure.`,
        ben1:`Orthopedic services effectively treat musculoskeletal pain caused by injuries, arthritis, or conditions like osteoporosis.`,
        ben2:`Through surgery, physical therapy, and rehabilitation, orthopedic services help patients regain movement and flexibility.`,
        ben3:`Orthopedic specialists offer advice, treatments, and preventative measures to reduce the risk of future injuries and musculoskeletal issues.`,
        ben4:`By addressing mobility and pain, orthopedic services improve overall quality of life, allowing individuals to engage in daily activities with ease.`,
        ben5:` Orthopedic services are tailored to each patient’s specific condition, ensuring effective treatment and better outcomes.`,
        title:`Best Orthopedic in Kandivali | Sonik Shah`,
        slug_descp:` Discover the Best Orthopedic Care in Kandivali for superior treatment of bone and joint issues. Our expert orthopedic team offers personalized care .`,
        keyword:`Best Orthopedic in Kandivali`,
    },

    {
        id:1,
        slugs:"/hip-surgeon-kandivali",
        ServiceName: "Hip Replacement Surgery",
        img1:galleryData.hip1,
        img2:galleryData.hip2,
        img3: galleryData.hip3,
        serviceImg:galleryData.hip4,
        descp2:'Hip replacement surgery is performed by a hip surgeon in Kandivali to relieve severe pain and improve mobility in patients with damaged hip joints, often due to arthritis or fractures. It aims to replace the damaged hip joint with an artificial one to restore function and reduce discomfort.',
        descp3:`Hip replacement surgery is typically recommended for individuals suffering from severe hip pain and reduced mobility due to conditions like osteoarthritis or rheumatoid arthritis that have not responded to conservative treatments. Candidates should be evaluated by a healthcare professional to determine if surgery is appropriate based on their overall health and the impact of their hip condition on daily life.`,
        descp4:`When seeking the top hip surgeon in Kandivali specializing in robotic knee replacement, examining before-and-after surgery images can offer valuable insights. These visual records effectively demonstrate the surgeon's expertise and the anticipated results of the procedure.`,
        ben1:'Orthopedic services effectively treat musculoskeletal pain caused by injuries, arthritis, or conditions like osteoporosis.',
        ben2:`Through surgery, physical therapy, and rehabilitation, orthopedic services help patients regain movement and flexibility.`,
        ben3:`Orthopedic specialists offer advice, treatments, and preventative measures to reduce the risk of future injuries and musculoskeletal issues.`,
        ben4:`By addressing mobility and pain, orthopedic services improve overall quality of life, allowing individuals to engage in daily activities with ease.`,
        ben5:`Orthopedic services are tailored to each patient’s specific condition, ensuring effective treatment and better outcomes.`,
        title:`Hip Surgeon in Kandivali | Sonik Shah`,
        slug_descp:`Find the leading Hip Surgeon in Kandivali for comprehensive hip care. Our expert specializes in advanced surgical techniques, providing personalized care.`,
        keyword:`Hip Surgeon in Kandivali`,
    },
    {
        id:1,
        slugs:"/best-arthroscopy-doctors-kandivali",
        ServiceName: "Arthroscopy",
        img1: galleryData.arth1,
        img2:galleryData.arth2,
        img3: galleryData.arth3,
        serviceImg:galleryData.arth4,
        ServiceDescription:"Arthroscopy is a minimally invasive surgical procedure used to diagnose and treat joint problems using a small camera called an arthroscope. The best arthroscopy doctors in Kandivali specialize in performing this procedure to ensure precise treatment and faster recovery for patients.",
        descp2:`Arthroscopy surgery is performed to diagnose and treat joint problems, offering a minimally invasive option with faster recovery times. The Best Arthroscopy doctors in Kandivali specialize in this procedure, using advanced techniques to address issues in joints such as the knee, shoulder, and hip.`,
        descp3:`Individuals experiencing persistent joint pain, swelling, or limited mobility that hasn't responded to non-surgical treatments should consider consulting with Best Arthroscopy Doctors in Kandivali. These specialists can evaluate and determine if arthroscopy is the appropriate procedure for diagnosing and treating joint issues.`,
        descp4:`When seeking the top arthroscopy doctors in Kandivali specializing in robotic knee replacement, examining before-and-after surgery images can offer valuable insights. These visual records effectively demonstrate the doctor's expertise and the anticipated results of the procedure.`,
        ben1:`Arthroscopy requires small incisions, reducing tissue damage, scarring, and recovery time compared to traditional surgery.`,
        ben2:`Due to its minimally invasive nature, patients typically experience shorter hospital stays and faster recovery times.`,
        ben3:`The smaller incisions and less invasive approach usually result in less post-surgical pain.`,
        ben4:`Arthroscopy allows surgeons to both diagnose and treat joint problems with high precision, improving outcomes.`,
        ben5:`With reduced downtime and faster healing, patients can return to normal activities, including sports, more quickly.`,
        title:`Best Arthroscopy Doctors in Kandivali | Sonik Shah`,
        slug_descp:'Discover the Best Arthroscopy Doctors in Kandivali offering specialized surgical expertise for joint problems. Our experts provide advanced treatment.',
        keyword:`Best Arthroscopy Doctors in Kandivali`
    },  
]

export default serviceData